<template>
    <el-tabs v-model="active" @tab-click="getInfo()">
        <el-tab-pane label="消息设置" name="0" class="descriptionsBox">
            <el-descriptions title="数字资源审核" :column="2" class="m_b2">
                <el-descriptions-item label="审核处理通知" v-if="info.audit.remind">
                    <el-radio-group v-model="info.audit.remind">
                        <el-radio label="0">开启</el-radio>
                        <el-radio label="1">关闭</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="审核结果通知" v-if="info.result.remind">
                    <el-radio-group v-model="info.result.remind">
                        <el-radio label="0">开启</el-radio>
                        <el-radio label="1">关闭</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
            </el-descriptions>
            <el-button class="saveBtn" type="primary" @click="submitMessageSet()" size="small">保存</el-button>
        </el-tab-pane>
        <el-tab-pane label="审核设置" name="1">
            <Audit :activeTab="2" v-if="active == 1"></Audit>
        </el-tab-pane>
        <el-tab-pane label="水印设置" name="2">
            <el-form size="small" :model="inputForm" :rules="rules" ref="inputForm" label-width="110px" v-loading="loading">
                <el-form-item prop="text" label="水印内容：">
                    <el-input v-model.trim="inputForm.text" maxlength="20" placeholder="请输入水印内容（20字内）"
                              clearable style="width: 60%"></el-input>
                </el-form-item>
                <el-form-item prop="enable" label="是否开启：">
                    <el-switch v-model="inputForm.enable" active-value="0" inactive-value="1"></el-switch>
                </el-form-item>
                <el-form-item>
                    <el-button class="saveBtn" type="primary" @click="submitWatermarkSet(0)" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-tab-pane>
        <el-tab-pane label="数据设置" name="3">
            <el-descriptions title="图片上传数量" class="m_b1"></el-descriptions>
            <el-form size="small" :model="dataForm" :rules="dataRules" ref="dataForm" label-width="110px" v-loading="loading">
                <el-form-item prop="text" label="最大张数：">
                    <el-input v-model.trim="dataForm.text" maxlength="3" placeholder="1~500"
                              clearable style="width: 60%" @input="dataForm.text = dataForm.text.replace(/[^\d]/g, '')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="saveBtn" type="primary" @click="submitWatermarkSet(1)" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import Audit from './audit'
    export default {
        components: {
            Audit
        },
        data() {
            var validatorNum = (rule, value, callback) => {
                if(value && !/^[0-9]*[1-9][0-9]*$/.test(value)) {
                    callback(new Error('请输入正确的数量'));
                    return
                }
                if(value && value > 500) {
                    callback(new Error('请输入正确的数量'));
                    return
                }
                callback();
            };
            return {
                loading: false,
                active: '0',
                info: {
                    audit: {},
                    result: {},
                },
                inputForm: {
                    text: '',
                    enable: '',
                },
                rules: {
                    text: [
                        {required: true, message: '水印内容不能为空', trigger: 'blur'}
                    ]
                },
                dataForm: {
                    text: '',
                },
                dataRules: {
                    text: [
                        {required: true, message: '最大张数不能为空', trigger: 'blur'},
                        {validator: validatorNum, trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                if(this.active == 0) {
                    this.getMessageSet()
                }
                if(this.active == 2) {
                    this.getWatermarkSet(0)
                }
                if(this.active == 3) {
                    this.getWatermarkSet(1)
                }
            },
            // 获取消息设置
            getMessageSet() {
                this.loading = true
                this.$axios(this.api.auth.getByMessageType, {systemType: '0', messageType: ['6']}, 'post').then(data => {
                    if (data.data && data.status) {
                        data.data.map(item => {
                            if(item.notificationType == '0') {
                                this.$set(this.info, 'audit', item)
                            }
                            if(item.notificationType == '1') {
                                this.$set(this.info, 'result', item)
                            }
                        })
                        this.$nextTick(() => {
                            this.loading = false
                        })
                    }
                })
            },
            // 保存消息设置
            submitMessageSet() {
                let p = []
                for(let key in this.info){
                    if(this.info[key].id){
                        p.push(this.info[key])
                    }
                }
                this.$axios(this.api.auth.updateByType, p, 'put').then(data => {
                    if (data.status) {
                        this.$message.success('保存成功')
                    } else {
                        this.$message.error('保存失败')
                    }
                    this.getMessageSet()
                })
            },
            // 获取水印设置、数据设置
            getWatermarkSet(type) {
                this.loading = true
                this.$axios(this.api.digital.downloadSetting, {type}).then(data => {
                    if (data.status) {
                        if(type == 0) {
                            this.inputForm = data.data.records[0]
                        }
                        if(type == 1) {
                            this.dataForm = data.data.records[0]
                        }
                        this.loading = false
                    }
                })
            },
            // 保存水印设置、数据设置
            submitWatermarkSet(type) {
                let valiForm = 'inputForm', p = this.inputForm
                if(type == 1) {
                    valiForm = 'dataForm'
                    p = this.dataForm
                }
                this.$refs[valiForm].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$axios(this.api.digital.downloadSettingUpdateById, p, 'put').then(data => {
                            if (data.status) {
                                this.$message.success('保存成功')
                            } else {
                                this.$message.error('保存失败')
                            }
                            this.getWatermarkSet(type)
                            this.loading = false
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .descriptionsBox {
        padding: 20px;
        overflow-y: auto;
        height: calc(100vh - 300px);
    }
    .descriptionsBox >>> .el-radio-group {
        margin-top: 4px !important;
    }
    .saveBtn {
        position: fixed;
        bottom: 20px;
        left: 55%;
    }
</style>
